import { CustomDropdown } from "@ivueit/vue-engine";
import { ListItem } from "@mui/material";
import MDAvatar from "components/MDAvatar";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import NamedAvatar from "pages/components/NamedAvatar";
import { downloadFile } from "pages/profile/services/ProfileServices";
import { Company } from "pages/profile/utils/ProfileInterfaces";
import { useEffect, useState } from "react";

interface Props {
  portal: Company;
  availableUserRoles: string[];
  defaultRole: string;
  disableRoleChange: boolean;
  onRoleChange: (companyId: string, newRole: string) => void;
}

const listItemStyle = {
  display: "flex",
  flexFlow: "row nowrap",
  paddingTop: "10px",
  paddingBottom: "10px",
};

const PortalsListItem = ({
  portal,
  availableUserRoles,
  defaultRole,
  disableRoleChange,
  onRoleChange,
}: Props) => {
  const [profileImage, setProfileImage] = useState<string | null>(null);
  const [selectedRole, setSelectedRole] = useState<string>(defaultRole);

  const { logoFileId, name } = portal;

  useEffect(() => {
    setSelectedRole(defaultRole);
  }, [defaultRole]);

  useEffect(() => {
    const downloadPortalLogo = async () => {
      const hasImageId = logoFileId !== null && logoFileId.isNotEmpty();
      if (hasImageId) {
        const imageData = await downloadFile(logoFileId);
        if (imageData) {
          const { mimeType, data } = imageData;
          const url = `data:${mimeType};base64,${data}`;
          setProfileImage(url);
        } else {
          setProfileImage(null);
        }
      }
    };
    downloadPortalLogo();
  }, [logoFileId]);

  return (
    <ListItem disablePadding sx={listItemStyle}>
      <MDBox
        display="flex"
        alignItems="center"
        sx={{ flexBasis: "40%", flexShrink: "0" }}
      >
        {logoFileId.isNotEmpty() ? (
          profileImage ? (
            <MDAvatar src={profileImage} alt="Profile Image" size="md" />
          ) : (
            <MDAvatar>
              <NamedAvatar name={name} />
            </MDAvatar>
          )
        ) : (
          <MDAvatar>
            <NamedAvatar name={name} />
          </MDAvatar>
        )}
        <MDTypography
          variant="h6"
          sx={{ color: "#6e7491", marginLeft: "20px" }}
        >
          {name}
        </MDTypography>
      </MDBox>
      <MDBox
        display="flex"
        sx={{
          flexGrow: "1",
          minWidth: "0",
          ".MuiFormControl-root": { marginLeft: "0" },
          ".MuiOutlinedInput-input": { display: "block !important" },
        }}
      >
        <CustomDropdown
          title={"Role"}
          selectedItem={{ value: selectedRole, displayTitle: selectedRole }}
          availableValues={availableUserRoles.map((role) => {
            return { value: role, displayTitle: role };
          })}
          onChange={(newRole) => {
            setSelectedRole(newRole.value);
            onRoleChange(portal.id, newRole.value);
          }}
          disabled={disableRoleChange}
          maxWidth="100%"
        />
      </MDBox>
    </ListItem>
  );
};
export default PortalsListItem;
