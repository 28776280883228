import { mdiDelete, mdiPencil } from "@mdi/js";
import MDBox from "components/MDBox";
import Icon from "@mdi/react";
import { customBadgeStyle } from "@ivueit/vue-engine";
import { CustomBadge } from "pages/dashboard/components/CustomBadge";
import { IconButton } from "@mui/material";
import { tagItemStyle } from "../styles";

interface Props {
  tagName: string;
  onEditClick: () => void;
  onDeleteClick: () => void;
}

const TagItemComponent = (props: Props) => {
  return (
    <MDBox sx={tagItemStyle}>
      <CustomBadge
        content={props.tagName}
        max={parseInt(props.tagName)}
        borderRadius="8px"
        sx={customBadgeStyle}
      />
      <IconButton color="primary" onClick={props.onEditClick}>
        <Icon path={mdiPencil} size={0.75} />
      </IconButton>
      <IconButton color="error" onClick={props.onDeleteClick}>
        <Icon path={mdiDelete} size={0.75} />
      </IconButton>
    </MDBox>
  );
};
export default TagItemComponent;
