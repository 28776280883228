import moment from "moment";
import { ElapsedType } from "./interfaces/interfaces";

export const calculateElapsedTime = (nanosecond: string) => {
  const dateTimeFormat = "MM/DD/YYYY HH:mm:ss";
  const formattedDateTime =
    nanosecond.formatUTCNanoSecondsToString(dateTimeFormat);
  const elapsedTime = moment(formattedDateTime).fromNow();
  return elapsedTime.toUpperCase() === "A DAY AGO" ? "1 day ago" : elapsedTime;
};

export const differenceInDays = (type: ElapsedType, nanosecond: string) => {
  const dateTimeFormat = "MM/DD/YYYY HH:mm:ss";
  const formattedDate = nanosecond.formatUTCNanoSecondsToString(dateTimeFormat);
  switch (type) {
    case ElapsedType.newest:
      return moment().diff(moment(formattedDate), "days") <= 7;
    case ElapsedType.oldest:
      return moment().diff(moment(formattedDate), "days") > 7;
    default:
      return false;
  }
};
