import MDBox from "components/MDBox";
import { useState, useEffect, useMemo, memo } from "react";
import Icon from "@mdi/react";
import { MDButton } from "../../../vue-grid/helpers/imports";
import { mapToggleButtonStyle } from "../../../styles/VueDetailStyles";
import {
  useLoadScript,
  GoogleMap,
  OverlayView,
  OverlayViewF,
} from "@react-google-maps/api";
import { CustomMapMarker } from "./CustomMapMarker";
import {
  Coordinates,
  API_KEY,
  LIBRARIES,
  DEFAULT_ZOOM_LEVEL,
} from "./interfaces";
import { LatLng } from "@ivueit/vue-engine";
import { useFetchDeviceLocation } from "./useFetchDeviceLocation";
import { PlaceholderComponent } from "pages/dashboard/home/dashboard-home/components/PlaceholderComponent";

interface Props {
  onlyUpdateViewPortOnDrag?: boolean;
  showMarker?: boolean;
  handleMapDrag?: (result: any | null) => void;
  coordinates?: LatLng;
  markerComponents: JSX.Element[];
  /// PROPS FOR TOGGLE BUTTON TO THE TOP-RIGHT
  onClickToggleButton?: () => void;
  fullViewIconPath?: string;
  iconSize?: number;
}

export const CustomMapComponent = memo((props: Props) => {
  const { location } = useFetchDeviceLocation();
  /// State to handle the viewport
  const [viewport, setViewport] = useState<Coordinates | null>(null);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: API_KEY,
    libraries: LIBRARIES,
  });
  const [mapObject, setMapObject] = useState<any>();
  const [mapType, setMapType] = useState<string>("satellite");
  /// This variable is created in order to load the marker only when the map has been dragged
  const [hasDrag, setHasDrag] = useState<boolean>(true);
  /// Setting up map's control options
  const mapOptions = useMemo(() => {
    return {
      mapTypeControl: true,
      mapTypeId: mapType,
      streetViewControl: true,
      zoomControl: true,
      scaleControl: true,
      fullscreenControl: !props.fullViewIconPath,
    };
  }, [mapType, props.fullViewIconPath]);

  const onLoad = (map: any) => {
    setMapObject(map);
  };

  const onUnmount = () => {
    setMapObject(null);
  };

  ////////////////// MAP DRAG EVENT HANDLING ////////////////

  const handleReverseGeocoding = async (
    latitude: number,
    longitude: number
  ) => {
    try {
      const result = await convertCoordsToPlace(latitude, longitude);
      if (!result) {
        props.handleMapDrag(null);
        return;
      }
      setHasDrag(true);
      props.handleMapDrag(result);
      const newViewport = { latitude: latitude, longitude: longitude };
      setViewport(newViewport);
    } catch (error) {
      props.handleMapDrag(null);
      console.error("Error occurred while reverse geocoding:", error);
    }
  };

  const convertCoordsToPlace = (lat: number, lng: number): Promise<any> => {
    return new Promise((resolve, reject) => {
      const googleMapObject = window.google.maps;
      if (googleMapObject) {
        const geocoderService = new googleMapObject.Geocoder();
        const latLngService = new googleMapObject.LatLng(lat, lng);
        geocoderService.geocode(
          { location: latLngService },
          (results: any[], status: string) => {
            if (status === "OK" && results.length > 0) {
              const result = results[0];
              resolve(result);
            } else {
              resolve(null);
            }
          }
        );
      } else {
        reject(null);
      }
    });
  };

  const updateViewPortOnScaleChange = () => {
    if (mapObject) {
      const latitude = mapObject.center.lat();
      const longitude = mapObject.center.lng();
      if (props.onlyUpdateViewPortOnDrag) {
        setViewport({ latitude: latitude, longitude: longitude });
        return;
      }
      handleReverseGeocoding(latitude, longitude);
    }
  };

  const generateMarkerComponent = () => {
    return (
      <OverlayViewF
        key={"marker"}
        position={{
          lat: viewport.latitude,
          lng: viewport.longitude,
        }}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      >
        <CustomMapMarker
          lat={viewport.latitude}
          lng={viewport.longitude}
          color="#4CAF50"
        />
      </OverlayViewF>
    );
  };

  useEffect(() => {
    if (props.coordinates) {
      setViewport({
        latitude: props.coordinates.latitude,
        longitude: props.coordinates.longitude,
      });
    } else {
      if (location) {
        setViewport({
          latitude: location.latitude,
          longitude: location.longitude,
        });
      }
    }
  }, [location, props.coordinates]);

  return isLoaded && viewport ? (
    <>
      {props.fullViewIconPath && (
        <MDButton
          sx={{
            ...mapToggleButtonStyle,
            right: "10px !important",
            top: "10px !important",
          }}
          iconOnly
          onClick={props.onClickToggleButton}
        >
          <Icon path={props.fullViewIconPath} size={props.iconSize ?? 1.0} />
        </MDButton>
      )}
      <MDBox sx={{ height: "100%", width: "100%" }}>
        <GoogleMap
          center={{ lat: viewport.latitude, lng: viewport.longitude }}
          mapContainerStyle={{
            height: "100%",
            width: "100%",
          }}
          onLoad={onLoad}
          options={mapOptions}
          onUnmount={onUnmount}
          zoom={DEFAULT_ZOOM_LEVEL}
          onDragEnd={updateViewPortOnScaleChange}
          onZoomChanged={updateViewPortOnScaleChange}
          onMapTypeIdChanged={() => {
            if (mapObject) {
              setMapType(mapObject.mapTypeId);
            }
          }}
        >
          {hasDrag && props.showMarker
            ? generateMarkerComponent()
            : props.markerComponents}
        </GoogleMap>
      </MDBox>
    </>
  ) : (
    <>
      <MDBox
        sx={{
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          height: "auto",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <PlaceholderComponent label={"Fetching location..."} />
      </MDBox>
    </>
  );
});
