/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useLayoutEffect } from "react";

// react-router components
import { useLocation } from "react-router-dom";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import GlobalStyles from "@mui/material/GlobalStyles";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDBadge from "components/MDBadge";

// Material Dashboard 2 PRO React TS examples components
import Breadcrumbs from "layouts/Breadcrumbs";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  notificationGlobalStyle,
} from "layouts/DashboardNavbar/styles";

// Material Dashboard 2 PRO React context
import { useMaterialUIController, setTransparentNavbar } from "context";
import CustomLogoutButton from "pages/dashboard/components/CustomLogoutButton";
import { breadcrumbConfig } from "../../constants";
import React from "react";
import { CustomPopper } from "pages/dashboard/home/vues/vue-grid/components/CustomPopper";
import NotificationsPopperContent from "pages/notifications/NotificationsPopperContent";
import { Backdrop } from "@mui/material";
import { useAuth } from "context/AuthProvider";

// Declaring prop types for DashboardNavbar
interface Props {
  absolute?: boolean;
  light?: boolean;
  isMini?: boolean;
}

function DashboardNavbar(props: Props): JSX.Element {
  const { absolute = false, isMini = false, light = false } = props;
  const [navbarType, setNavbarType] = useState<
    "fixed" | "absolute" | "relative" | "static" | "sticky"
  >();
  const [controller, dispatch] = useMaterialUIController();
  const { transparentNavbar, fixedNavbar, darkMode } = controller;
  const routesFromURL = useLocation().pathname.split("/").slice(1);
  const { unreadNotificationCount } = useAuth();
  /// Breadcrumbs
  const getBreadCrumbs = () => {
    for (const key in breadcrumbConfig) {
      if (routesFromURL.includes(key)) {
        const { routeItems, newlyExtendedItem } = breadcrumbConfig[key];
        var data: string[];
        if (
          routesFromURL.includes("media-hub") ||
          routesFromURL.includes("invoices")
        ) {
          data =
            // Album detail screen & invoices screen
            routesFromURL.length > 2
              ? [...routeItems, routesFromURL[2]]
              : // Gallery screen
                [...routeItems];
        } else if (routesFromURL.length > 2) {
          data = [...routeItems, routesFromURL[1], newlyExtendedItem];
        } else if (routesFromURL.length > 1) {
          data = [...routeItems, routesFromURL[1]];
        } else {
          data = routeItems;
        }
        return data;
      }
    }
    return routesFromURL;
  };

  useLayoutEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(
        dispatch,
        (fixedNavbar && window.scrollY === 0) || !fixedNavbar
      );
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const [anchorEl, setAnchorE1] = useState<HTMLButtonElement | null>(null);
  const [popperName, setPopperName] = useState<string | null>(null);

  const getPopper = () => {
    return (
      <Backdrop open={true} sx={{ zIndex: 1200 }}>
        <CustomPopper
          onClickAway={() => {
            setPopperName(null);
          }}
          shouldOpen={true}
          anchorElement={anchorEl}
          placement={"bottom-end"}
          id={popperName}
        >
          <NotificationsPopperContent
            onClosePopper={() => {
              setPopperName(null);
            }}
          />
        </CustomPopper>
      </Backdrop>
    );
  };

  // Styles for the navbar icons
  const iconsStyle = ({
    palette: { dark, white, text },
    functions: { rgba },
  }: {
    palette: any;
    functions: any;
  }) => ({
    color: () => {
      let colorValue = white.main;

      return colorValue;
    },
  });

  return (
    <>
      <GlobalStyles styles={notificationGlobalStyle} />
      <AppBar
        position={absolute ? "absolute" : navbarType}
        color="inherit"
        sx={(theme) =>
          navbar(theme, { transparentNavbar, absolute, light, darkMode })
        }
      >
        <Toolbar sx={navbarContainer}>
          <MDBox color="inherit" mb={{ xs: 0, md: 0 }} sx={() => navbarRow()}>
            <Breadcrumbs
              title={getBreadCrumbs()[getBreadCrumbs().length - 1]}
              route={getBreadCrumbs()}
              light={light}
            />
          </MDBox>
          {isMini ? null : (
            <MDBox sx={() => navbarRow()}>
              {
                <MDBox color={light ? "white" : "inherit"}>
                  <IconButton
                    size="small"
                    color="inherit"
                    sx={{ ...navbarIconButton, zIndex: 1201 }}
                    type="button"
                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                      setAnchorE1(event.currentTarget);
                      setPopperName(
                        popperName === "notifications" ? null : "notifications"
                      );
                    }}
                  >
                    <MDBadge
                      badgeContent={unreadNotificationCount > 0 ? unreadNotificationCount : null}
                      color="badgeError"
                      size="xs"
                      circular
                    >
                      <Icon sx={iconsStyle}>notifications</Icon>
                    </MDBadge>
                  </IconButton>
                  {popperName !== null && getPopper()}
                </MDBox>
              }
              <MDBox pl={3} sx={{ width: "fit-content" }}>
                <CustomLogoutButton />
              </MDBox>
            </MDBox>
          )}
        </Toolbar>
      </AppBar>
    </>
  );
}

export default DashboardNavbar;
