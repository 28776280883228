import {
  FormControlLabel,
  Checkbox,
  List,
  ListItem,
  Tooltip,
} from "@mui/material";
import DialogSectionTitle from "./DialogSectionTitle";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import PortalsListItem from "./PortalsListItem";
import { useEffect, useState } from "react";
import { Company, UserInfo } from "pages/profile/utils/ProfileInterfaces";
import { getCompanyDetailsById } from "../services/userServices";
import { WebServiceStatus } from "utils/services/AppUrls";
import {
  ADMIN_ROLE,
  AVAILABLE_USER_ROLES,
  CompanyRoleResult,
  UserRoles,
  getRoleDescriptionFromRole,
  getRoleFromDescription,
} from "../interfaces/interfaces";
import { useAuth } from "context/AuthProvider";
import { tooltipStyles } from "pages/dashboard/home/site-lists/styles/site_list_style";
import CustomSnackbar, {
  CustomSnackbarContent,
} from "pages/components/CustomSnackbar";

interface Props {
  email: string;
  roles: CompanyRoleResult;
  isAdmin: boolean;
  onRoleChange: (companyId: string, newRole: UserRoles) => void;
}

const PortalAccessContent = (props: Props) => {
  const { email, roles, isAdmin } = props;
  const { getAvailablePortalsExcludingReadOnly } = useAuth();
  const { userData }: { userData: UserInfo } = useAuth();
  const companiesAccessibleByAdmin: Company[] = getAvailablePortalsExcludingReadOnly();
  const [snackbarContent, setSnackbarContent] =
    useState<CustomSnackbarContent | null>(null);
  const [isCompanyAdmin, setIsCompanyAdmin] = useState(isAdmin);
  const [defaultUserRole, setDefaultUserRole] = useState(UserRoles.user);
  const [availableCompanyList, setAvailableCompanyList] = useState<Company[]>(
    []
  );
  const disableRoleEditing =
    (email ?? "").trim().toLowerCase() ===
    (userData?.email ?? "").trim().toLowerCase();

  // Fetching the data
  // Updates the roles for company, for the selected user
  const updateRoleForCompanies = (companyList: Company[]): Company[] => {
    return companyList.map((currentCompany) => {
      var companyWithRole = { ...currentCompany };
      const newRole = roles.companyRoles.find(
        (item) => item.companyId === currentCompany.id
      );
      // Ensures it's not a new user
      companyWithRole.role = newRole?.role ?? UserRoles.noAccess;
      return companyWithRole;
    });
  };

  useEffect(() => {
    const fetchCompanyDetails = async () => {
      const companyIds = companiesAccessibleByAdmin.map(
        (company) => company.id
      );
      var portalList: Company[] = [];
      const responses = await Promise.all(
        companyIds.map((companyId) => getCompanyDetailsById(companyId))
      );
      responses.forEach((response) => {
        if (response.status === WebServiceStatus.success) {
          const companyDetails = response.data as Company;
          portalList.push(companyDetails);
          if (portalList.length === companyIds.length) {
            const updatedList = updateRoleForCompanies(portalList);
            setAvailableCompanyList(updatedList);
          }
        } else {
          console.error("Some data could not be found");
        }
      });
    };
    fetchCompanyDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, roles.companyRoles]);

  useEffect(() => {
    setDefaultUserRole(isCompanyAdmin ? UserRoles.admin : UserRoles.user);
    const updatedCompanies = availableCompanyList.map((company) => {
      const newRole = isCompanyAdmin ? UserRoles.admin : UserRoles.user;
      props.onRoleChange(company.id, newRole);
      return {
        ...company,
        role: newRole,
      };
    });
    setAvailableCompanyList(updatedCompanies);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCompanyAdmin]);

  const generatePortalListFromCompanyList = () => {
    return availableCompanyList.map((company, index) => {
      const currentRole = (company.role as UserRoles) ?? defaultUserRole;
      const roleDescription = getRoleDescriptionFromRole(currentRole);
      let allRoles = [...AVAILABLE_USER_ROLES];
      if (isCompanyAdmin) {
        allRoles.push(ADMIN_ROLE);
      }
      return (
        <PortalsListItem
          key={index}
          portal={company}
          availableUserRoles={allRoles}
          defaultRole={roleDescription}
          disableRoleChange={
            disableRoleEditing || defaultUserRole === UserRoles.admin
          }
          onRoleChange={(companyId: string, newRole: string) => {
            const role = getRoleFromDescription(newRole);
            props.onRoleChange(companyId, role);
          }}
        />
      );
    });
  };

  const getNoPortalPlaceholder = () => {
    return (
      <MDBox display="flex" textAlign="center">
        <MDTypography
          variant="caption"
          sx={{
            fontWeight: "600",
            fontSize: "14px",
            marginBottom: "4px",
            color: "#6e7491",
          }}
        >
          No portals available
        </MDTypography>
      </MDBox>
    );
  };
  return (
    <>
      <DialogSectionTitle title="Portal Access" />
      <MDBox
        display="flex"
        alignItems="center"
        mb="30px"
        sx={{
          ".MuiFormControlLabel-root": {
            display: "flex",
          },
        }}
      >
        <Tooltip
          title="Cannot edit your own roles."
          arrow
          placement="left"
          componentsProps={{
            tooltip: {
              sx: tooltipStyles,
            },
          }}
          disableHoverListener={!disableRoleEditing}
        >
          <span>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isCompanyAdmin}
                  disabled={
                    disableRoleEditing || availableCompanyList.length <= 0
                  }
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setIsCompanyAdmin(event.target.checked);
                  }}
                />
              }
              label={
                <MDBox display="flex" flexDirection="column">
                  <MDTypography
                    variant="caption"
                    sx={{
                      fontWeight: "600",
                      marginBottom: "4px",
                      color: "#6e7491",
                    }}
                  >
                    Make Company Admin
                  </MDTypography>
                  <MDTypography variant="caption" sx={{ color: "#6e7491" }}>
                    Admins can create and manage system, settings, users, site
                    list and Vues.
                  </MDTypography>
                </MDBox>
              }
              sx={{ marginRight: "4px" }}
            />
          </span>
        </Tooltip>
      </MDBox>
      <List
        sx={{
          display: "flex",
          flexFlow: "column nowrap",
        }}
      >
        <ListItem
          disablePadding
          sx={{ display: "flex", flexFlow: "row nowrap", marginBottom: "20px" }}
        >
          <MDBox sx={{ display: "flex", flexBasis: "40%", flexShrink: "0" }}>
            <MDTypography variant="h6" sx={{ fontSize: "14px" }}>
              PORTALS
            </MDTypography>
          </MDBox>
          <MDBox sx={{ display: "flex", flexGrow: "1" }}>
            <MDTypography variant="h6" sx={{ fontSize: "14px" }}>
              ROLE
            </MDTypography>
          </MDBox>
        </ListItem>
        {availableCompanyList.length > 0
          ? generatePortalListFromCompanyList()
          : getNoPortalPlaceholder()}
      </List>
      <CustomSnackbar
        snackbarContent={snackbarContent}
        onClose={() => {
          setSnackbarContent(null);
        }}
      />
    </>
  );
};

export default PortalAccessContent;
