import { mdiCloseCircleOutline, mdiDownload } from "@mdi/js";
import {
  IconButton,
  LinearProgress,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import Icon from "@mdi/react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import React, { useState } from "react";
import MDButton from "components/MDButton";
import { useLocation, useNavigate } from "react-router-dom";
import { RoutePath } from "../../constants";
import {
  downloadFileToDevice,
  generatePresignedFileURL,
  getFileNameInUTC,
  linkElementStyle,
  WebServiceStatus,
} from "@ivueit/vue-engine";
import {
  notificationListStyle,
  secondaryText,
  clearBtn,
  notifyBadge,
  buttonOutlineStyles,
  listItemTextStyle,
  notificationDurationStyle,
  listItemStyle,
} from "./NotificationStyles";
import { CustomIcon } from "./components/CustomIcon";
import { calculateElapsedTime } from "./helpers";
import { ClientNotification, NotificationType } from "./interfaces/interfaces";
import { useAuth } from "context/AuthProvider";
import {
  hideClientNotificationFromDropdown,
  markAllNotificationsAsRead,
  markNotificationAsRead,
} from "./services/NotificationServices";

const NotificationsPopperContent = ({
  onClosePopper,
}: {
  onClosePopper: () => void;
}) => {
  const {
    notifications,
    fetchTopTenNotifications,
  }: {
    notifications: ClientNotification[];
    fetchTopTenNotifications: Function;
  } = useAuth();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [showLoader, setShowLoader] = useState(false);

  const handleViewAllNotificationsClick = () => {
    if (pathname === RoutePath.notifications) {
      onClosePopper();
    }
    navigate(RoutePath.notifications);
  };

  const handleMarkAllAsReadButton = async () => {
    setShowLoader(true);
    const response = await markAllNotificationsAsRead();
    setShowLoader(false);
    if (response.status === WebServiceStatus.success) {
      // Updating the notifications by triggering the api call
      fetchTopTenNotifications();
    } else {
      console.log("Failed to mark all notification as read", response.error);
    }
  };

  const handleCloseNotification = async (notification: ClientNotification) => {
    const { id } = notification;
    // Clicking the button shall delete the notification and remove it from the dropdown but not from the notifications page.
    setShowLoader(true);
    const response = await hideClientNotificationFromDropdown(id);
    setShowLoader(false);
    if (response.status === WebServiceStatus.success) {
      // Updating the notifications by triggering the api call
      fetchTopTenNotifications();
    } else {
      console.log(
        "Failed to remove notification from dropdown",
        response.error
      );
    }
  };

  const handleExportedFileDownload = async (
    fileID: string,
    type: NotificationType
  ) => {
    setShowLoader(true);
    // Represents the file name, based on the export type
    let exportType = NotificationType.batchExported ? "Survey" : "Vue";
    const response = await generatePresignedFileURL(fileID);
    setShowLoader(false);
    if (response.status === WebServiceStatus.success) {
      const presignedUrl = response.data.url;
      const fileName = `export_${exportType}_Export_Excel_${getFileNameInUTC()}_UTC.csv`;
      try {
        await downloadFileToDevice(presignedUrl, fileName, "text/csv");
      } catch (error) {
        console.log(`Failed to download the file: ${error}`);
      }
    }
  };

  const handleNotificationItemClick = async (
    notification: ClientNotification
  ) => {
    if (!notification.isRead) {
      // Checks whether the notifcation is already read or not
      // Clicking on an unread notification should marks it as read
      await markAsRead(notification);
    }
    const { typeOf, link } = notification;
    switch (typeOf) {
      case NotificationType.batchExported:
      case NotificationType.vueExported:
        const fileID = link.replace("/file/", "");
        if (fileID.isNotEmpty()) {
          // Generates presigned file url and download
          handleExportedFileDownload(fileID, notification.typeOf);
        }
        // Export (shall be a link behind the scene)-automatically starts downloading the file.
        break;
      case NotificationType.vueCompleted:
      case NotificationType.vueEscalated:
        // Redirects user to the Individual Vue Page.
        const vueID = link.replace("/vues/", "");
        if (vueID.isNotEmpty() && parseInt(vueID) > 0) {
          // Navigates to Individual vue page, with vueID
          navigate(`${RoutePath.vues}/${vueID}`);
        }
        break;
      default:
        break;
    }
  };

  const markAsRead = async (notification: ClientNotification) => {
    const { id } = notification;
    setShowLoader(true);
    const response = await markNotificationAsRead([id]);
    setShowLoader(false);
    if (response.status === WebServiceStatus.success) {
      // Updating the notifications by triggering the api call
      fetchTopTenNotifications();
    } else {
      console.log("Failed to update read status", response.error);
    }
  };

  return (
    <>
      <MDBox display="flex" padding="22px" alignItems="center">
        <MDTypography variant="h5">Notifications</MDTypography>
        <Link
          sx={{ ...linkElementStyle, textTransform: "none", cursor: "pointer" }}
          onClick={handleMarkAllAsReadButton}
          variant="button"
          color="primary"
          fontWeight="regular"
          underline="always"
          ml="auto"
        >
          Mark all as read
        </Link>
      </MDBox>
      {showLoader && (
        <MDBox mx={2} overflow="hidden" borderRadius="3px">
          <LinearProgress color="success" />
        </MDBox>
      )}
      {notifications.length <= 0 && (
        <MDBox
          display="flex"
          flexDirection="column"
          minHeight="120px"
          justifyContent="center"
          alignItems="center"
        >
          <MDTypography variant="p" fontSize="14px" sx={secondaryText}>
            No notifications available
          </MDTypography>
        </MDBox>
      )}
      <List sx={notificationListStyle}>
        {notifications.map((notification) => {
          const { typeOf, title, message, createdAt, isRead } = notification;
          return (
            <ListItem
              alignItems="flex-start"
              sx={listItemStyle}
              onClick={() => {
                handleNotificationItemClick(notification);
              }}
            >
              <ListItemIcon sx={{ minWidth: "48px" }}>
                {<CustomIcon type={typeOf} />}
              </ListItemIcon>
              <ListItemText
                primary={title}
                sx={listItemTextStyle}
                secondary={
                  <React.Fragment>
                    <MDTypography
                      variant="p"
                      fontSize="12px"
                      sx={secondaryText}
                    >
                      {message}
                    </MDTypography>
                    <MDTypography
                      variant="p"
                      fontSize="12px"
                      opacity="0.64"
                      sx={notificationDurationStyle}
                    >
                      {calculateElapsedTime(createdAt)}
                    </MDTypography>
                  </React.Fragment>
                }
              />

              <MDBox
                display="flex"
                ml="auto"
                justifyContent="end"
                alignItems="start"
              >
                {(typeOf === NotificationType.batchExported ||
                  typeOf === NotificationType.vueExported) && (
                  <IconButton
                    sx={{ padding: "2px", marginTop: "5px" }}
                    color="primary"
                    onClick={() => {
                      handleNotificationItemClick(notification);
                    }}
                  >
                    <Icon path={mdiDownload} size={1} />
                  </IconButton>
                )}
                <MDBox
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                >
                  <IconButton
                    color="secondary"
                    sx={clearBtn}
                    onClick={() => {
                      handleCloseNotification(notification);
                    }}
                  >
                    <Icon path={mdiCloseCircleOutline} size={0.75} />
                  </IconButton>
                  {!isRead && <MDBox sx={notifyBadge}></MDBox>}
                </MDBox>
              </MDBox>
            </ListItem>
          );
        })}
      </List>
      <MDBox display="flex" justifyContent="center" marginBottom="38px">
        <MDButton
          variant="outlined"
          color="primary"
          size="medium"
          sx={buttonOutlineStyles}
          onClick={handleViewAllNotificationsClick}
        >
          View All Notifications
        </MDButton>
      </MDBox>
    </>
  );
};

export default NotificationsPopperContent;
