import { Breakpoints } from "@mui/material";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "layouts/DashboardLayout";
import DashboardNavbar from "layouts/DashboardNavbar";
import { ReactNode } from "react";

const DashboardContentLayout = ({
  children,
  needCardBackground = true,
  containerRef,
}: {
  children?: ReactNode;
  needCardBackground?: boolean;
  containerRef?: React.Ref<HTMLDivElement>;
}) => {
  const style = (breakpoints: Breakpoints) => ({
    minHeight: `calc(100vh - 100px)`,
    maxHeight: `calc(100vh - 100px)`,
    overflowY: "auto",
    [breakpoints.up("lg")]: {
      height: `calc(100vh - 100px)`,
    },
  });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={1}>
        {needCardBackground ? (
          <Card sx={({ breakpoints }) => style(breakpoints)}>{children}</Card>
        ) : (
          <MDBox
            sx={({ breakpoints }) => style(breakpoints)}
            ref={containerRef}
          >
            {children}
          </MDBox>
        )}
      </MDBox>
    </DashboardLayout>
  );
};

export default DashboardContentLayout;
