import { WebServiceResponse } from "@ivueit/vue-engine";
import { NotificationAPIServices } from "utils/services/AppUrls";
import {
  performGetRequest,
  performPostRequest,
} from "utils/services/NetworkHandler";

export const getAllNotifications = async (
  parameters: string
): Promise<WebServiceResponse> => {
  const url = `${NotificationAPIServices.getClientNotifications}?${parameters}`;
  return await performGetRequest(url);
};

export const markNotificationAsRead = async (
  notificationIds: string[]
): Promise<WebServiceResponse> => {
  return await performPostRequest(
    NotificationAPIServices.markClientNotificationsAsRead,
    {
      ids: notificationIds,
    }
  );
};

export const markAllNotificationsAsRead =
  async (): Promise<WebServiceResponse> => {
    return await performPostRequest(
      NotificationAPIServices.markAllNotificationsAsRead
    );
  };

export const hideClientNotificationFromDropdown = async (
  notificationId: string
): Promise<WebServiceResponse> => {
  return await performPostRequest(
    NotificationAPIServices.hideClientNotificationFromDropdown,
    {
      id: notificationId,
    }
  );
};
