import DashboardContentLayout from "layouts/DashboardContentLayout";
import MediaHubGallery from "./MediaHubGallery";
import MDBox from "components/MDBox";

export const AlbumDetailScreen = () => {
  return (
    <DashboardContentLayout needCardBackground={false}>
      <MDBox display="flex" flexDirection="column" minHeight="0" pl="20px" pr="10px" height="calc(100vh - 100px)">
        <MediaHubGallery isGalleryScreen={false} />
      </MDBox>
    </DashboardContentLayout>
  );
};
