import { List, ListItem, Link, InputAdornment } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardContentLayout from "layouts/DashboardContentLayout";
import { linkElementStyle } from "../NotificationStyles";
import MDInput from "components/MDInput";
import { differenceInDays } from "../helpers";
import { NotificationDetailItem } from "./NotificationDetailItem";
import { alphanumericSort } from "utils/helpers/extensions";
import {
  DEFAULT_PAGE_SIZE,
  RoutePath,
  TEXTFIELD_CHANGE_DELAY,
} from "../../../constants";
import { useState, useEffect, useRef } from "react";
import { Search } from "@mui/icons-material";
import {
  ClientNotification,
  ClientNotificationResponse,
  ElapsedType,
  NotificationType,
} from "../interfaces/interfaces";
import {
  getAllNotifications,
  markAllNotificationsAsRead,
  markNotificationAsRead,
} from "../services/NotificationServices";
import {
  CustomIndicator,
  downloadFileToDevice,
  generatePresignedFileURL,
  getFileNameInUTC,
  WebServiceStatus,
} from "@ivueit/vue-engine";
import CustomSnackbar, {
  CustomSnackbarContent,
} from "pages/components/CustomSnackbar";
import { useNavigate } from "react-router-dom";
import {
  defaultPaginationMetaData,
  PaginationMetaData,
} from "pages/my-account/manage-users/interfaces/interfaces";
import { useAuth } from "context/AuthProvider";

export const NotificationDetailScreen = () => {
  const {
    fetchTopTenNotifications,
  }: {
    fetchTopTenNotifications: Function;
  } = useAuth();
  const [searchText, setSearchText] = useState<string>("");
  /// This is to avoid the unwanted useEffect invocation unless if the search text hasn't chnaged
  const [searchTextChanged, setSearchTextChanged] = useState<boolean>(false);
  const [metaValues, setmetaValues] = useState<PaginationMetaData>(
    defaultPaginationMetaData
  );
  const [notificationsList, setNotificationsList] = useState<
    ClientNotification[]
  >([]);
  const [showLoader, setShowLoader] = useState(false);
  const [snackbarContent, setSnackbarContent] =
    useState<CustomSnackbarContent | null>(null);
  const navigate = useNavigate();
  /// Ref is used to handle the scroll
  const containerRef = useRef<HTMLDivElement>(null);

  // Notification screen needs only 10 items to be displayed.
  const fetchAllNotifications = async (isScrolling: boolean) => {
    // Default page number will be 1 if not scrolling
    const pageNumber = isScrolling ? metaValues.pageNumber + 1 : 1;
    setShowLoader(true);
    const response = await getAllNotifications(
      `pageSize=${DEFAULT_PAGE_SIZE}&pageNumber=${pageNumber}&includeIsRead.value=true&includeShowInDropdown.value=true&searchTerm.value=${searchText}`
    );
    setShowLoader(false);
    if (response.status === WebServiceStatus.success) {
      const notificationData = response.data as ClientNotificationResponse;
      const { notifications, meta } = notificationData;
      setNotificationsList(notifications ?? []);
      setmetaValues(meta);
    } else {
      console.log("Error in fetching notifications: ", response.error);
    }
  };

  const getNotificationsList = (type: ElapsedType) => {
    switch (type) {
      case ElapsedType.newest:
        return notificationsList
          .filter((notification) => {
            const { createdAt } = notification;
            const isNewest = differenceInDays(type, createdAt);
            return isNewest;
          })
          .sort((b, a) => alphanumericSort(a.createdAt, b.createdAt))
          .map((notification) => (
            <NotificationDetailItem
              notificationItem={notification}
              onItemClick={() => {
                handleNotificationItemClick(notification);
              }}
            />
          ));
      case ElapsedType.oldest:
        return notificationsList
          .filter((notification) => {
            const { createdAt } = notification;
            const isOldest = differenceInDays(type, createdAt);
            return isOldest;
          })
          .sort((b, a) => alphanumericSort(a.createdAt, b.createdAt))
          .map((notification) => (
            <NotificationDetailItem
              notificationItem={notification}
              onItemClick={() => {
                handleNotificationItemClick(notification);
              }}
            />
          ));
      default:
        return [<></>];
    }
  };

  const handleMarkAllAsReadButton = async () => {
    setShowLoader(true);
    const response = await markAllNotificationsAsRead();
    setShowLoader(false);
    if (response.status === WebServiceStatus.success) {
      fetchTopTenNotifications();
      // Updating the notifications by triggering the api call
      fetchAllNotifications(false);
    } else {
      console.log("Failed to mark all notification as read", response.error);
    }
  };

  const handleExportedFileDownload = async (
    fileID: string,
    type: NotificationType
  ) => {
    setShowLoader(true);
    // Represents the file name, based on the export type
    let exportType = NotificationType.batchExported ? "Survey" : "Vue";
    const response = await generatePresignedFileURL(fileID);
    setShowLoader(false);
    if (response.status === WebServiceStatus.success) {
      const presignedUrl = response.data.url;
      const fileName = `export_${exportType}_Export_Excel_${getFileNameInUTC()}_UTC.csv`;
      try {
        await downloadFileToDevice(presignedUrl, fileName, "text/csv");
        setSnackbarContent({
          title: `Exported ${exportType}!`,
          message: `File downloaded successfully.`,
          isError: false,
        });
      } catch (error) {
        setSnackbarContent({
          title: "Attention!",
          message: "Failed to download the file",
          isError: true,
        });
      }
    }
  };

  const handleNotificationItemClick = async (
    notification: ClientNotification
  ) => {
    if (!notification.isRead) {
      // Checks whether the notifcation is already read or not
      // Clicking on an unread notification should marks it as read
      await markAsRead(notification);
    }
    const { typeOf, link } = notification;
    switch (typeOf) {
      case NotificationType.batchExported:
      case NotificationType.vueExported:
        const fileID = link.replace("/file/", "");
        if (fileID.isNotEmpty()) {
          // Generates presigned file url and download
          handleExportedFileDownload(fileID, notification.typeOf);
        }
        // Export (shall be a link behind the scene)-automatically starts downloading the file.
        break;
      case NotificationType.vueCompleted:
      case NotificationType.vueEscalated:
        // Redirects user to the Individual Vue Page.
        const vueID = link.replace("/vues/", "");
        if (vueID.isNotEmpty() && parseInt(vueID) > 0) {
          // Navigates to Individual vue page, with vueID
          navigate(`${RoutePath.vues}/${vueID}`);
        }
        break;
      default:
        break;
    }
  };

  const markAsRead = async (notification: ClientNotification) => {
    const { id } = notification;
    setShowLoader(true);
    const response = await markNotificationAsRead([id]);
    setShowLoader(false);
    if (response.status === WebServiceStatus.success) {
      fetchTopTenNotifications();
      // Updating the notifications by triggering the api call
      fetchAllNotifications(false);
    } else {
      console.log("Failed to update read status", response.error);
    }
  };

  const getNoNotificationContent = () => {
    return (
      <MDBox
        minHeight="58vh"
        display="flex"
        alignItems="center"
        justifyContent="center"
        p={5}
      >
        <MDTypography variant="p" fontSize="16px">
          {showLoader ? "Loading..." : "No notifications available"}
        </MDTypography>
      </MDBox>
    );
  };

  const getNotificationListBody = () => {
    return (
      <MDBox ref={containerRef}>
        <List sx={{ ".MuiListItem-root:last-child": { borderBottom: "none" } }}>
          {getNotificationsList(ElapsedType.newest).length !== 0 && (
            <ListItem
              sx={{
                pb: "12px",
                mb: "4px",
                borderBottom: "1px solid rgb(233, 234, 237)",
                "&:last-child": {
                  borderBottom: "none",
                },
              }}
            >
              <MDTypography variant="h6">Recent</MDTypography>
            </ListItem>
          )}
          {getNotificationsList(ElapsedType.newest)}
        </List>
        <List sx={{ ".MuiListItem-root:last-child": { borderBottom: "none" } }}>
          {getNotificationsList(ElapsedType.oldest).length !== 0 && (
            <ListItem
              sx={{
                pb: "12px",
                mb: "4px",
                borderBottom: "1px solid rgb(233, 234, 237)",
              }}
            >
              <MDTypography variant="h6">More Than a Week Ago</MDTypography>
            </ListItem>
          )}
          {getNotificationsList(ElapsedType.oldest)}
        </List>
      </MDBox>
    );
  };

  useEffect(() => {
    fetchAllNotifications(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /// Handle search field change
  useEffect(() => {
    if (searchTextChanged) {
      const searchByVueName = (searchText: string) => {
        fetchAllNotifications(false);
      };
      const delaySearchAction = setTimeout(() => {
        searchByVueName(searchText.trim());
      }, TEXTFIELD_CHANGE_DELAY);
      return () => clearTimeout(delaySearchAction);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      const handleOnRowsScrollEnd = async () => {
        const { scrollTop, scrollHeight, clientHeight } = container;
        const hasReachedEnd =
          Math.abs(scrollHeight - clientHeight - scrollTop) < 1;
        const shouldScroll =
          notificationsList.length < metaValues.totalElements &&
          metaValues.pageNumber < metaValues.totalPages;
        if (shouldScroll && hasReachedEnd) {
          await fetchAllNotifications(true);
        }
      };
      container.addEventListener("scroll", handleOnRowsScrollEnd);
      return () => {
        container.removeEventListener("scroll", handleOnRowsScrollEnd);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metaValues, notificationsList.length]);

  return (
    <DashboardContentLayout needCardBackground>
      <MDBox pt={5} pr={8.6} pl={8.6}>
        <MDBox>
          <MDTypography variant="h5">Notifications</MDTypography>
        </MDBox>
        <MDBox pt={3.8} pb={3.8} display="flex" alignItems="flex-start">
          <MDBox flexGrow="1" maxWidth="390px">
            <MDInput
              fullWidth
              value={searchText}
              placeholder="Search Vue"
              InputLabelProps={{ shrink: true }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setSearchTextChanged(true);
                setSearchText(event.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Search fontSize="medium" sx={{ color: "#344767" }} />
                  </InputAdornment>
                ),
              }}
            />
          </MDBox>
          <Link
            sx={{ ...linkElementStyle, textTransform: "none" }}
            onClick={handleMarkAllAsReadButton}
            variant="button"
            color="primary"
            fontWeight="regular"
            underline="always"
            ml="auto"
          >
            Mark all as read
          </Link>
        </MDBox>
        {showLoader && <CustomIndicator />}
        {notificationsList.length > 0
          ? getNotificationListBody()
          : getNoNotificationContent()}
      </MDBox>
      <CustomSnackbar
        snackbarContent={snackbarContent}
        onClose={() => {
          setSnackbarContent(null);
        }}
      />
    </DashboardContentLayout>
  );
};
