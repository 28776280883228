import colors from "assets/theme/base/colors";

export const checkBoxStyle = {
  marginRight: "21px",
  ".MuiFormControlLabel-label": {
    fontWeight: "normal",
    color: colors.inputBorderColorDark,
    fontSize: "12px",
    marginLeft: 0,
    verticalAlign: "middle",
  },
};

export const searchBarStyle = {
  maxWidth: "300px",
  width: "100%",
  input: {
    paddingTop: "10px",
    paddingBottom: "10px",
    "::placeholder": {
      color: "#6E7491",
      opacity: 1,
    },
  },
};

export const yesChipStyle = {
  borderRadius: "8px",
  fontSize: "14px",
  fontWeight: "bold",
  bgcolor: "#EFD1CE",
  color: "error.main",
  height: "auto",
  lineHeight: "20px",
  paddingTop: "4px",
  paddingBottom: "4px",
};

export const noChipStyle = {
  fontSize: "14px",
  borderRadius: "4px",
  fontWeight: "regular",
  bgcolor: "transparent.main",
  color: "dark.main",
};

export const linkElementStyle = {
  textDecoration: "underline !important",
  fontSize: "14px",
  textFillColor: "unset",
  marginLeft: 0,
  "&:link, &:visited": {
    textDecoration: "underline !important",
  },
  "&:hover": {
    textDecoration: "underline !important",
  },
};

export const linkBoldStyle = {
  fontWeight: "700",
  cursor: "pointer",
  fontSize: "16px",
  textDecoration: "none",
  "&:hover": { textDecoration: "none" },
};

export const datePickerStyle = {
  ".MuiDateCalendar-root": {
    maxWidth: "256px",
  },
  ".MuiDateCalendar-viewTransitionContainer": {
    padding: `0 16px`,
  },
  ".MuiPickersDay-root": {
    fontWeight: "500 !important",
    fontSize: "14px !important",
    margin: "0 !important",
    width: "32px !important",
    height: "32px !important",
  },
  ".MuiPickersDay-root:not(.Mui-disabled)": {
    color: "#191919 !important",
    "&.Mui-selected": {
      backgroundColor: "#4caf50 !important",
      color: "white !important",
    },
  },
  ".MuiPickersCalendarHeader-label": {
    fontSize: "14px !important",
    fontWeight: "600 !important",
    color: "#344767 !important",
    marginRight: "0 !important",
  },
  ".MuiDayCalendar-weekDayLabel": {
    color: "#344767 !important",
    fontWeight: "normal !important",
  },
  ".MuiDayCalendar-slideTransition": {
    minHeight: "206px !important",
    height: "auto !important",
  },
  ".MuiDialogActions-root": {
    "&.MuiPickersLayout-actionBar": {
      paddingTop: "8px",
      paddingBottom: "8px",
      "& .MuiButton-text": {
        "&.MuiButton-textPrimary": {
          border: "1px solid #c7ccff !important",
          color: "#344767 !important",
        },
      },
    },
  },
  ".MuiYearCalendar-root": {
    width: "100% !important",
    maxHeight: "174px !important",
    "& .MuiPickersYear-yearButton": {
      fontSize: "14px !important",
      height: "24px !important",
      "&.Mui-selected": {
        backgroundColor: "#4caf50 !important",
      },
    },
  },
};

export const timePickerStyle = {
  ".MuiDialogActions-root": {
    "&.MuiPickersLayout-actionBar": {
      paddingTop: "8px",
      paddingBottom: "8px",
      "& .MuiButton-text": {
        "&.MuiButton-textPrimary": {
          border: "1px solid #c7ccff !important",
          color: "#344767 !important",
        },
      },
    },
  },
  ".MuiMultiSectionDigitalClock-root.MuiList-root, .MuiList-root.MuiMultiSectionDigitalClockSection-root":
    {
      width: "62px",
      paddingRight: "7px",
      paddingLeft: "7px",
      ".MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters, .MuiMultiSectionDigitalClockSection-item":
        {
          minWidth: "unset !important",
          color: "#344767 !important",
          margin: "2px 0px",
          "&.Mui-selected": {
            color: "#ffffff !important",
            background: "#63b967 !important",
            "&:hover": {
              background: "#4CAF50 !important",
            },
          },
        },
      "&::-webkit-scrollbar": {
        width: "5px",
      },
      "&::-webkit-scrollbar-track": {
        background: "#f1f1f1",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#ccc",
        borderRadius: "2px",
        "&:hover": {
          background: "#aaa",
        },
      },
    },
};
