import {
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import React from "react";
import {
  listItemStyle,
  listItemTextStyle,
  secondaryText,
  notifyBadge,
  notificationDurationStyle,
} from "../NotificationStyles";
import { calculateElapsedTime } from "../helpers";
import { CustomIcon } from "./CustomIcon";
import { ClientNotification, NotificationType } from "../interfaces/interfaces";
import { mdiDownload } from "@mdi/js";
import Icon from "@mdi/react";

export const NotificationDetailItem = ({
  notificationItem,
  onItemClick,
}: {
  notificationItem: ClientNotification;
  onItemClick: () => void;
}) => {
  const { title, message, createdAt, typeOf, isRead } = notificationItem;

  return (
    <ListItem
      sx={{
        ...listItemStyle,
        borderBottom: "1px solid rgb(233, 234, 237)",
        mt: "12px",
        pb: "12px",
        alignItems: "normal",
      }}
      onClick={onItemClick}
    >
      <ListItemIcon sx={{ minWidth: "48px", marginTop: "0" }}>
        {<CustomIcon type={typeOf} />}
      </ListItemIcon>
      <ListItemText
        primary={title}
        sx={{ ...listItemTextStyle, maxWidth: "unset" }}
        secondary={
          <React.Fragment>
            <MDTypography variant="p" fontSize="12px" sx={secondaryText}>
              {message}
            </MDTypography>
          </React.Fragment>
        }
      />
      <MDBox
        display="flex"
        flexDirection="column"
        ml="auto"
        alignItems="end"
        justifyContent="space-between"
      >
        {!isRead && <MDBox sx={notifyBadge}></MDBox>}

        {(typeOf === NotificationType.batchExported ||
          typeOf === NotificationType.vueExported) && (
          <IconButton sx={{ padding: "2px", marginTop: "5px" }} color="primary" onClick={onItemClick}>
            <Icon path={mdiDownload} size={1} />
          </IconButton>
        )}

        <MDTypography
          variant="p"
          fontSize="12px"
          opacity="0.64"
          sx={{ ...notificationDurationStyle, marginLeft: "auto", mt: "auto" }}
        >
          {calculateElapsedTime(createdAt)}
        </MDTypography>
      </MDBox>
    </ListItem>
  );
};
