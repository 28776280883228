import {
  MAXIMUM_NOTIFICATIONS_ON_DROPDOWN,
  NOTIFICATION_REFRESH_TIMER,
  RoutePath,
} from "./../constants";
import {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { AuthContext } from "./Context";
import { Company, UserInfo } from "pages/profile/utils/ProfileInterfaces";
import { Moment } from "moment";
import { alphanumericSort, parseJWTToken } from "utils/helpers/extensions";
import { UserRoles } from "pages/my-account/manage-users/interfaces/interfaces";
import {
  ClientNotification,
  ClientNotificationResponse,
} from "pages/notifications/interfaces/interfaces";
import { getAllNotifications } from "pages/notifications/services/NotificationServices";
import { WebServiceStatus } from "@ivueit/vue-engine";

export enum LocalStorageKeys {
  email = "email",
  authToken = "authToken",
  userData = "userData",
  shouldRemember = "rememberUser",
  vuePhoto = "vuePhoto",
  loginTime = "loginTime",
}

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  /// Used for navigation
  const navigate = useNavigate();
  /// Stores the current user's email
  const [email, setEmail] = useLocalStorage(LocalStorageKeys.email, null);
  /// Storing the auth token
  const [authToken, setAuthToken] = useLocalStorage(
    LocalStorageKeys.authToken,
    null
  );
  /// Storing the userInfo
  const [userData, setUserData] = useLocalStorage(
    LocalStorageKeys.userData,
    null
  );
  const [notifications, setNotifications] = useState<ClientNotification[]>([]);
  const [unreadNotificationCount, setUnreadNotificationCount] =
    useState<number>(0);
  /// This defines whether the account setUp is successful or not
  const [isSetupSuccess, setIsSetupSuccess] = useState<boolean>(false);
  /// This handles whether the email should be remembered or not
  const [shouldRemember, setShouldRemember] = useLocalStorage(
    LocalStorageKeys.shouldRemember,
    null
  );
  /// Storing the userInfo
  const [loginTime, setLoginTime] = useLocalStorage(
    LocalStorageKeys.loginTime,
    null
  );
  /// Storing the User Role
  const [userRole, setUserRole] = useState(UserRoles.admin);

  // Used to login to the application
  const login = useCallback(
    async (email: any) => {
      setEmail(email);
    },
    [setEmail]
  );

  /// Storing the authtoken
  const storeAuthToken = useCallback(
    async (data: any) => {
      setAuthToken(data);
    },
    [setAuthToken]
  );

  /// Logout - Sets the user as null
  const logOut = useCallback(() => {
    setUserData(null);
    setAuthToken(null);
    navigate(RoutePath.root, {
      replace: true,
    });
  }, [navigate, setUserData, setAuthToken]);

  // Notification screen needs only 10 items to be displayed.
  // Method loads only 10 items, should contain read items as well
  const fetchTopTenNotifications = async () => {
    const response = await getAllNotifications(
      `pageSize=${MAXIMUM_NOTIFICATIONS_ON_DROPDOWN}&pageNumber=1&includeIsRead.value=true&includeShowInDropdown.value=false`
    );
    if (response.status === WebServiceStatus.success) {
      const notificationData = response.data as ClientNotificationResponse;
      const { notifications, totalUnread } = notificationData;
      setNotifications(notifications ?? []);
      setUnreadNotificationCount(totalUnread ?? 0);
    } else {
      console.log("Error in notifications: ", response.error);
    }
  };

  useEffect(() => {
    // Get User role from JWtToken
    const updateUserRole = async () => {
      if (userData && authToken) {
        const companyId = userData.companyId ?? "";
        const parsedData: any = (await parseJWTToken(authToken)) ?? null;
        const companyRoles: any = parsedData?.cmproles ?? null;
        const adminRole: any = parsedData?.role ?? null;
        if (adminRole && adminRole === UserRoles.superAdmin) {
          // Setting super admin
          setUserRole(UserRoles.superAdmin);
        } else if (companyRoles) {
          // Updating role
          let newRole = companyRoles[companyId] ?? UserRoles.user;
          setUserRole(newRole);
        }
      } else if (!authToken) {
        // Auth token is not available, moving the user out
        //TODO Need to check the logic
        // logOut();
        return;
      }
    };

    updateUserRole();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authToken, userData]);

  useEffect(() => {
    /// fetches top 10 notifications on regular intervals
    const interval = setInterval(() => {
      // API should be called only when the user is logged in and we have valid auth token
      if (authToken) {
        fetchTopTenNotifications();
      }
    }, NOTIFICATION_REFRESH_TIMER);

    fetchTopTenNotifications();

    return () => {
      clearInterval(interval);
    };
  }, [authToken]);

  /// This decides whether the setUp is successful or not
  const setAccountSetupStatus = useCallback(
    (status: boolean, shouldNavigate: boolean = true) => {
      setIsSetupSuccess(status);
      if (shouldNavigate) {
        navigate(RoutePath.root);
      }
    },
    [navigate]
  );

  /// Sets the current user details
  const storeUserData = useCallback(
    async (user: UserInfo) => {
      setUserData(user);
    },
    [setUserData]
  );
  /// Sets the Login Time of user
  const storeUserLoginTime = useCallback(
    async (loginTime: Moment) => {
      setLoginTime(loginTime);
    },
    [setLoginTime]
  );

  /// Whether the email should be remembered or not
  const shouldRememberUser = useCallback(
    async (data: boolean) => {
      setShouldRemember(data);
    },
    [setShouldRemember]
  );

  // Provides the userInfo object for the currently logged in user
  const getCurrentUserData = useCallback((): UserInfo | null => {
    return userData;
  }, [userData]);

  // Provides all available companies (unique) for the currently logged in users
  const getAvailablePortalsForUser = useCallback((): Company[] => {
    const userData = getCurrentUserData();
    if (userData !== null) {
      var companies = userData.companies;
      const companyGroups = userData.companyGroups;
      const allCompanies = companies.concat(
        companyGroups.flatMap((group) => {
          return group.companies;
        })
      );
      const availablePortalsForUser = [
        ...new Map(allCompanies.map((item) => [item.id, item])).values(),
      ];
      let sortedList = availablePortalsForUser.sort((a, b) =>
        alphanumericSort(a.name, b.name)
      );
      // Filtering out role from the token
      const parsedData: any = parseJWTToken(authToken) ?? null;
      const newRoles: any = parsedData?.cmproles ?? null;
      // Updating the role to the company list
      return sortedList.map((company) => {
        let newCompany = company;
        if (newRoles && newRoles[newCompany.id]) {
          newCompany.role = newRoles[newCompany.id];
        }
        return newCompany;
      });
    } else {
      return [];
    }
  }, [authToken, getCurrentUserData]);

  const getAvailablePortalsExcludingReadOnly = useCallback((): Company[] => {
    const allCompanies = getAvailablePortalsForUser();
    return allCompanies.filter(
      (company) => company.role !== UserRoles.immutableReadOnly
    );
  }, [getAvailablePortalsForUser]);

  const value = useMemo(
    () => ({
      email,
      login,
      logOut,
      storeAuthToken,
      isSetupSuccess,
      setAccountSetupStatus,
      storeUserData,
      authToken,
      userData,
      shouldRememberUser,
      shouldRemember,
      getAvailablePortalsForUser,
      getAvailablePortalsExcludingReadOnly,
      loginTime,
      storeUserLoginTime,
      userRole,
      notifications,
      unreadNotificationCount,
      fetchTopTenNotifications,
    }),
    [
      email,
      login,
      logOut,
      storeAuthToken,
      getAvailablePortalsForUser,
      getAvailablePortalsExcludingReadOnly,
      isSetupSuccess,
      setAccountSetupStatus,
      authToken,
      storeUserData,
      userData,
      shouldRemember,
      shouldRememberUser,
      loginTime,
      storeUserLoginTime,
      userRole,
      notifications,
      unreadNotificationCount,
    ]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
