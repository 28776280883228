// @mui material components
import Card from "@mui/material/Card";
// react-router-dom components
import { useNavigate } from "react-router-dom";
// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
// Authentication layout components
import CoverLayout from "layouts/CoverLayout";
// Image
import bgImage from "assets/images/authentication-cover.jpeg";
import Icon from "@mdi/react";
import { mdiLock } from "@mdi/js";
import MDButton from "components/MDButton";
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { useState } from "react";
import { useAuth } from "context/AuthProvider";
import {
  requestEmailPincode,
  requestSMSPincodeWithEmail,
} from "pages/authentication/services/AuthenticationServices";
import { WebServiceStatus } from "utils/services/AppUrls";
import { RoutePath } from "./../../../constants";
import { InfoOutlined } from "@mui/icons-material";
import MessageAlert from "../components/MessageAlert";
import { VerificationPageType } from "../sms-verification/SMSVerification";
import { CustomIndicator } from "pages/components/CustomIndicator";

export interface secondFactorAuthParams {
  emailId: string;
  auditId: string;
  recipient: string;
  codeType: CodeType;
}

export enum CodeType {
  mobile,
  email,
}

const TwoFactorAuthentication = () => {
  const [hasError, setHasError] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [selectedOption, setSelectedOption] = useState("phone");
  /// Used to fetch the parameters passed while navigation
  const { email, logOut } = useAuth();
  /// Hook for navigation
  const navigateToVerificationPage = useNavigate();
  /// State to show/hide progress indicator
  const [showLoader, setShowLoader] = useState<boolean>(false);

  /******** METHODS ***********/

  const handleRadioSelectionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSelectedOption(event.target.value);
  };

  // Handles the continue button click
  const handleContinueButtonClick = () => {
    sendPincodeAndNavigate();
  };

  // Handles the previous button click
  const handlePreviousButtonClick = () => {
    // Clears the saved data and takes the user to the signin page
    logOut();
  };

  const sendPincodeAndNavigate = async () => {
    setShowLoader(true);
    const emailId = email as string;
    const isPhone = selectedOption === "phone";
    const typeBasedOnSelection = isPhone ? CodeType.mobile : CodeType.email;
    const response = isPhone
      ? await requestSMSPincodeWithEmail(emailId)
      : await requestEmailPincode(emailId);
    if (response.status === WebServiceStatus.success) {
      setShowLoader(false);
      const auditId = response.data.auditId as string;
      const recipient = response.data.recipient as string;
      const params: secondFactorAuthParams = {
        emailId: emailId,
        auditId: auditId,
        recipient: recipient,
        codeType: typeBasedOnSelection,
      };
      navigateToVerificationPage(RoutePath.smsVerification, {
        state: {
          type: VerificationPageType.is2FA,
          data: params,
        },
      });
    } else {
      setShowLoader(false);
      setHasError(true);
      const error = response.error as string;
      setError(error);
    }
  };

  const radioStyle = {
    ".MuiSvgIcon-root": {
      borderColor: "#6E7491 !important",
    },
    "span.MuiTypography-root": {
      fontSize: "14px",
      fontWeight: "500",
    },
    ".Mui-checked": {
      ".MuiSvgIcon-root": {
        borderColor: "#63b967 !important",
      },
    },
  };

  return (
    <>
      {showLoader && <CustomIndicator />}
      <CoverLayout image={bgImage}>
        <Card>
          <MDBox pt={4} px={16} pb={2} display="flex" justifyContent="center">
            <MDAvatar
              size="xxl"
              bgColor="info"
              alt="Avatar"
              sx={{ fontSize: "3.6em", width: "142px", height: "142px" }}
            >
              <Icon path={mdiLock} size={3} />
            </MDAvatar>
          </MDBox>
          <MDBox pb={3} px={3}>
            {hasError && (
              <MDBox px={3}>
                <MessageAlert
                  message={error}
                  type="error"
                  icon={<InfoOutlined />}
                />
              </MDBox>
            )}
            <MDBox mt={2} mb={3}>
              <MDTypography
                textAlign="center"
                fontWeight="bold"
                fontSize="22px"
                color="black"
              >
                Choose an Authentication Method
              </MDTypography>
            </MDBox>
            <FormControl sx={radioStyle}>
              <RadioGroup
                value={selectedOption}
                onChange={handleRadioSelectionChange}
              >
                <FormControlLabel
                  value="phone"
                  control={<Radio />}
                  label="Phone (SMS)"
                />
                <FormControlLabel
                  value="email"
                  control={<Radio />}
                  label="Email"
                />
              </RadioGroup>
            </FormControl>
            <MDBox mt={3}>
              <MDButton
                variant="gradient"
                color="info"
                fontSize="14px"
                fontWeight="bold"
                sx={{
                  "&:hover, &:disabled, &:enabled": {
                    fontSize: "14px",
                    fontWeight: "bold",
                  },
                }}
                fullWidth
                onClick={handleContinueButtonClick}
              >
                CONTINUE
              </MDButton>
            </MDBox>
            <MDBox mt={2}>
              <MDButton
                variant="outlined"
                sx={{
                  borderColor: "#c7ccd0",
                  color: "#344767",
                  fontSize: "14px",
                  fontWeight: "bold",
                  "&:hover": {
                    borderColor: "#c7ccd0",
                    color: "#344767",
                  },
                }}
                fullWidth
                onClick={handlePreviousButtonClick}
              >
                PREVIOUS
              </MDButton>
            </MDBox>
          </MDBox>
        </Card>
      </CoverLayout>
    </>
  );
};

export default TwoFactorAuthentication;
