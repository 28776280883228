import {
  WebServiceStatus,
  dialogCancelButtonStyle,
  dialogSaveButtonStyle,
} from "@ivueit/vue-engine";
import { Search } from "@mui/icons-material";
import {
  TextField,
  InputAdornment,
  Checkbox,
  FormControlLabel,
  FormGroup,
  DialogActions,
  DialogContent,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useEffect, useState } from "react";
import { alphanumericSort } from "utils/helpers/extensions";
import {
  addMediasToAlbum,
  getAllAlbumsInMyCompany,
} from "../services/MediaHubServices";
import { AlbumTag, ClientMediaInfo } from "../utils/interfaces";
import CustomSnackbar, {
  CustomSnackbarContent,
} from "pages/components/CustomSnackbar";
import { GENERIC_ERROR_MESSAGE } from "./../../../../../constants";
import { PlaceholderComponent } from "../../dashboard-home/components/PlaceholderComponent";
import { CustomIndicator } from "pages/components/CustomIndicator";

interface Props {
  handleCancelClick: (completed: boolean) => void;
  selectedMediaItems: ClientMediaInfo[];
}

export const AddToAlbumDialogContent = (props: Props) => {
  const [searchInput, setSearchInput] = useState<string>("");
  const [showLoader, setShowLoader] = useState(false);
  const [selectedAlbums, setSelectedAlbums] = useState<AlbumTag[]>([]);
  const [albumNames, setAlbumNames] = useState<AlbumTag[]>([]);
  const [snackbarContent, setSnackbarContent] =
    useState<CustomSnackbarContent | null>(null);

  useEffect(() => {
    const fetchAvailableAlbumList = async () => {
      setShowLoader(true);
      const response = await getAllAlbumsInMyCompany();
      if (response.status === WebServiceStatus.success) {
        const data = response.data as { albums: AlbumTag[] };
        const { albums } = data;
        setAlbumNames(albums);
      } else {
        setSnackbarContent({
          title: "Attention!",
          message: response.error ?? GENERIC_ERROR_MESSAGE,
          isError: true,
        });
      }
      setShowLoader(false);
    };
    fetchAvailableAlbumList();
  }, []);

  /// Handles the onChange of the search input
  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const input = event.target.value;
    setSearchInput(input);
  };

  const handleSaveButtonClick = async () => {
    setShowLoader(true);
    const mediaItemIds = props.selectedMediaItems.map((e) => e.id);
    const selectedAlbumIds = selectedAlbums.map((album) => album.id);
    const response = await addMediasToAlbum(selectedAlbumIds, mediaItemIds);
    if (response.status === WebServiceStatus.success) {
      props.handleCancelClick(true);
    } else {
      setSnackbarContent({
        title: "Attention!",
        message: response.error ?? GENERIC_ERROR_MESSAGE,
        isError: true,
      });
    }
    setShowLoader(false);
  };

  const getAlbumNameList = () => {
    if (albumNames.length <= 0 && !showLoader) {
      return (
        <PlaceholderComponent label={"No albums available"} fontSize="18px" />
      );
    }
    return albumNames
      .sort((a, b) => alphanumericSort(a.name, b.name))
      .filter((album) =>
        album.name.toLowerCase().includes(searchInput.trim().toLowerCase())
      )
      .map((album, index) => {
        return (
          <FormGroup key={index}>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(event, checked) => {
                    let newAlbums = [...selectedAlbums];
                    if (checked) {
                      newAlbums.push(album);
                    } else {
                      // Removing current album from the list
                      newAlbums = newAlbums.filter(
                        (thisAlbum) => thisAlbum.id !== album.id
                      );
                    }
                    setSelectedAlbums(newAlbums);
                  }}
                />
              }
              label={album.name}
              sx={{
                ".MuiFormControlLabel-label": {
                  fontWeight: "400",
                },
              }}
            />
          </FormGroup>
        );
      });
  };

  return (
    <>
      {showLoader && <CustomIndicator />}
      <MDBox p={1.6}>
        <TextField
          fullWidth
          placeholder="Search Albums"
          value={searchInput}
          InputLabelProps={{ shrink: true }}
          onChange={handleSearchInputChange}
          sx={{ py: 1 }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search fontSize="medium" sx={{ color: "#344767" }} />
              </InputAdornment>
            ),
          }}
        />
      </MDBox>
      <DialogContent>
        <MDBox minHeight="250px">{getAlbumNameList()}</MDBox>
      </DialogContent>
      <DialogActions>
        <MDBox px={2} py={2} display="flex" justifyContent="flex-end">
          <MDButton
            variant="outlined"
            sx={dialogCancelButtonStyle}
            size="small"
            onClick={() => props.handleCancelClick(false)}
          >
            CANCEL
          </MDButton>
          <MDButton
            variant="gradient"
            color="success"
            sx={dialogSaveButtonStyle}
            size="small"
            onClick={handleSaveButtonClick}
            disabled={selectedAlbums.length <= 0}
          >
            SAVE
          </MDButton>
        </MDBox>
      </DialogActions>
      <CustomSnackbar
        snackbarContent={snackbarContent}
        onClose={() => {
          setSnackbarContent(null);
        }}
      />
    </>
  );
};
