import { Company } from "@ivueit/vue-engine";
import { UserRoles } from "pages/my-account/manage-users/interfaces/interfaces";

interface Props {
  userRole: UserRoles;
  requiredRole: UserRoles;
}

export const hasMinimumRequiredRole = ({
  userRole,
  requiredRole,
}: Props): boolean => {
  switch (userRole) {
    case UserRoles.superAdmin:
      // Access to everything
      return true;
    case UserRoles.admin:
      // Access to everything
      return requiredRole !== UserRoles.superAdmin;
    case UserRoles.manager:
      // Access to non admin items
      return (
        requiredRole !== UserRoles.superAdmin &&
        requiredRole !== UserRoles.admin
      );
    case UserRoles.user:
      // Access to non admin/manager items
      return (
        requiredRole !== UserRoles.superAdmin &&
        requiredRole !== UserRoles.admin &&
        requiredRole !== UserRoles.manager
      );
    case UserRoles.immutableReadOnly:
      // Access to read only items
      return (
        requiredRole !== UserRoles.superAdmin &&
        requiredRole !== UserRoles.admin &&
        requiredRole !== UserRoles.manager &&
        requiredRole !== UserRoles.user
      );
    case UserRoles.noAccess:
      return false;
  }
};

// Checks whether the given client id having read only access in the available company list
export const hasReadOnlyAccessForCompanyId = (
  clientId: string,
  availableCompanyList: Company[]
): boolean => {
  const company = availableCompanyList.find(
    (company) => company.id === clientId
  );
  if (company && company.role) {
    return company.role === UserRoles.immutableReadOnly;
  }
  return false;
};

// generates a formatted address from address components
export const getFormattedAddress = (
  streetNumber: string,
  route: string,
  city: string,
  state: string,
  zipcode: string,
  country: string
) => {
  let addressLines = [];
  const streetAddress = [streetNumber, route].filter(Boolean).join(" ");
  if (streetAddress) {
    addressLines.push(streetAddress);
  }

  const cityStateZip = [city, state, zipcode].filter(Boolean).join(", ");
  if (cityStateZip) {
    addressLines.push(cityStateZip);
  }

  if (country) {
    addressLines.push(country);
  }
  return addressLines.join(", ");
};
