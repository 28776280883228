/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useMemo, ReactNode, useRef, useCallback } from "react";

// react-chartjs-2 components
import { Chart, Line } from "react-chartjs-2";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// DefaultLineChart configurations
import configs from "layouts/Charts/LineChart/configs";

// Material Dashboard 2 PRO React TS Base Styles
import colors from "assets/theme/base/colors";

/// Defining type for line chart click
type onLineChartClick = (datasetIndex: number, index: number) => void;

// Declaring props types for DefaultLineChart
interface Props {
  icon?: {
    color?:
      | "primary"
      | "secondary"
      | "info"
      | "success"
      | "warning"
      | "error"
      | "light"
      | "dark";
    component: ReactNode;
  };
  title?: string;
  description?: string | ReactNode;
  height?: string | number;
  chart: {
    labels: string[];
    datasets: {
      label: string;
      color:
        | "open"
        | "inprogress"
        | "inreview"
        | "pastdue"
        | "completed"
        | "previousyear"
        | "presentyear"
        | string;
      data: number[];
    }[];
  };
  onClick?: onLineChartClick;
  [key: string]: any;
}

const { dark, iVueChartColors } = colors;

function LineChart({
  icon = { color: "info", component: "" },
  title = "",
  description = "",
  height = "19.125rem",
  chart,
  onClick,
}: Props): JSX.Element {
  const chartDatasets = chart.datasets
    ? chart.datasets.map((dataset) => ({
        ...dataset,
        tension: 0,
        pointRadius: 3,
        borderWidth: 4,
        backgroundColor: "transparent",
        fill: true,
        pointBackgroundColor: iVueChartColors[dataset.color]
          ? iVueChartColors[dataset.color]
          : dark.main,
        borderColor: iVueChartColors[dataset.color]
          ? iVueChartColors[dataset.color]
          : dark.main,
        maxBarThickness: 6,
      }))
    : [];

  const { data, options } = configs(chart.labels || [], chartDatasets);
  const chartRef = useRef<HTMLCanvasElement>(null);

  const handleLineChartClick = useCallback(
    (element: [any], event: any) => {
      /// Handling the click on the data point
      if (element.length > 0) {
        const clickedElement = element[0];
        const { index, datasetIndex } = clickedElement;
        if (onClick) {
          // Sending the selected data to the parent
          onClick(datasetIndex, index);
        }
      } else if (chartRef.current) {
        // Handling the click on the graph
        const chart = Chart.getChart(chartRef.current);
        const elementAtXAxis = chart!.getElementsAtEventForMode(
          event,
          "x",
          { axis: "y", intersect: false },
          true
        );
        if (elementAtXAxis.length > 0) {
          const clickedElement = elementAtXAxis[0];
          const { index, datasetIndex } = clickedElement;
          if (onClick) {
            // Sending the selected data to the parent
            onClick(datasetIndex, index);
          }
        }
      }
    },
    [onClick]
  );

  const renderChart = (
    <MDBox py={2} pr={2} pl={icon.component ? 1 : 2}>
      {title || description ? (
        <MDBox display="flex" px={description ? 1 : 0} pt={description ? 0 : 0}>
          {icon.component && (
            <MDBox
              width="4rem"
              height="4rem"
              bgColor={icon.color || "info"}
              variant="gradient"
              coloredShadow={icon.color || "info"}
              borderRadius="xl"
              display="flex"
              justifyContent="center"
              alignItems="center"
              color="white"
              mt={-5}
              mr={2}
            >
              <Icon fontSize="medium">{icon.component}</Icon>
            </MDBox>
          )}
          <MDBox mt={icon.component ? -2 : 0}>
            {title && <MDTypography variant="h6">{title}</MDTypography>}
            <MDBox mb={2}>
              <MDTypography component="div" variant="button" color="text">
                {description}
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      ) : null}
      {useMemo(
        () => (
          <MDBox height={height} sx={{ cursor: "pointer" }}>
            <Line
              data={data}
              options={options}
              ref={chartRef}
              getElementAtEvent={handleLineChartClick}
            />
          </MDBox>
        ),
        [data, handleLineChartClick, height, options]
      )}
    </MDBox>
  );

  return title || description ? <Card>{renderChart}</Card> : renderChart;
}

export default LineChart;
