import { PaginationMetaData } from "pages/my-account/manage-users/interfaces/interfaces";

export enum NotificationType {
  vueEscalated = "VUE_ESCALATED",
  vueCompleted = "VUE_COMPLETED",
  vueExported = "VUE_EXPORTED",
  batchExported = "BATCH_EXPORTED",
}

export enum ElapsedType {
  newest,
  oldest,
}

export interface ClientNotification {
  id: string;
  typeOf: NotificationType;
  title: string;
  message: string;
  link: string;
  recipientOrgUserId: string;
  createdAt: string;
  isRead: boolean;
  showInDropdown: boolean;
}

export interface ClientNotificationResponse {
  notifications: ClientNotification[];
  totalUnread: number;
  meta: PaginationMetaData;
}
