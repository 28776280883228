import { mdiPlus } from "@mdi/js";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { StyledButtonWithIcon } from "pages/dashboard/home/site-lists/components/SiteListActionBar";
import DialogSectionTitle from "pages/my-account/manage-users/components/DialogSectionTitle";
import { listSyles, tagListContainerStyle } from "../styles";
import TagItemComponent from "./TagItemComponent";
import { List } from "@mui/material";
import { TagItem } from "../interfaces/interfaces";
import { alphanumericSort } from "utils/helpers/extensions";

interface Props {
  title: string;
  message: string;
  tags: TagItem[];
  onClickAddTag: () => void;
  onEditClick: (tag: TagItem) => void;
  onDeleteClick: (tag: TagItem) => void;
}

const ManageTagSection = (props: Props) => {
  return (
    <MDBox>
      <DialogSectionTitle title={props.title} fontSize="20px" />
      <MDBox mb={4}>
        <StyledButtonWithIcon
          iconPath={mdiPlus}
          sx={{ marginBottom: "16px" }}
          onClick={props.onClickAddTag}
        >
          ADD TAG
        </StyledButtonWithIcon>
        <MDBox>
          <MDTypography variant="body2" fontWeight="regular">
            {props.message}
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox sx={tagListContainerStyle}>
        <MDBox display="flex">
          <List sx={listSyles}>
            {props.tags
              .sort((a, b) => alphanumericSort(a.name, b.name))
              .map((tag, index) => (
                <TagItemComponent
                  key={index}
                  tagName={tag.name}
                  onDeleteClick={() => {
                    props.onDeleteClick(tag);
                  }}
                  onEditClick={() => {
                    props.onEditClick(tag);
                  }}
                />
              ))}
          </List>
        </MDBox>
      </MDBox>
    </MDBox>
  );
};

export default ManageTagSection;
