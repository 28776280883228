import { DialogActions, DialogContent } from "@mui/material";
import UserInfoContent from "./UserInfoContent";
import PortalAccessContent from "./PortalAccessContent";
import { dialogCancelButtonStyle } from "@ivueit/vue-engine";
import MDButton from "components/MDButton";
import {
  CompanyRoleResult,
  UserFromAPI,
  UserGridData,
  UserRoles,
  generateUserObjectForGrid,
  getDefaultUserSummaryFromEmailAndPortals,
} from "../interfaces/interfaces";
import { useEffect, useState } from "react";
import { UserInfo } from "pages/profile/utils/ProfileInterfaces";
import MDTypography from "components/MDTypography";
import TooltipWrapper from "pages/components/TooltipWrapper";

interface Props {
  closeDialog: () => void;
  isEditing: boolean;
  userSummary?: UserFromAPI;
  user?: UserInfo;
  roles: CompanyRoleResult;
  isAdmin: boolean;
  onSave: (newUserData: UserGridData) => void;
}

const AddUserDialogContent = (props: Props) => {
  const isEditing = props.isEditing;
  const [newUser, setNewUser] = useState<UserInfo | null>(props.user);
  const [newRoles, setNewRoles] = useState<CompanyRoleResult>(props.roles);
  const [totalAccessibleSites, setTotalAccessibleSites] = useState(0);

  useEffect(() => {
    const sitesWithAccess = newRoles.companyRoles.filter(
      (companyRole) => companyRole.role !== UserRoles.noAccess
    ).length;

    setTotalAccessibleSites(sitesWithAccess);
  }, [newRoles]);

  const hasValidUserData = () => {
    return (
      newUser &&
      newUser.firstName.trim().isNotEmpty() &&
      newUser.lastName.trim().isNotEmpty() &&
      newUser.email.trim().isValidEmail() &&
      newUser.designation.trim().isNotEmpty() &&
      totalAccessibleSites > 0
    );
  };

  return (
    <>
      <DialogContent sx={{ padding: "32px 24px !important" }}>
        <UserInfoContent
          user={newUser}
          onChangeUserData={(newUserData: UserInfo) => {
            setNewUser(newUserData);
          }}
          isEditing={isEditing}
        />
        <PortalAccessContent
          email={newUser?.email ?? ""}
          roles={newRoles}
          isAdmin={isEditing ? props.isAdmin : false}
          onRoleChange={(companyId: string, newRole: UserRoles) => {
            const currentRoles: CompanyRoleResult = { ...newRoles };
            const indexOfCompany = currentRoles.companyRoles.findIndex(
              (role) => role.companyId === companyId
            );
            if (indexOfCompany !== -1) {
              currentRoles.companyRoles[indexOfCompany].role = newRole;
            } else {
              // Adding missing company roles
              currentRoles.companyRoles.push({
                companyId: companyId,
                role: newRole,
              });
            }
            currentRoles.email = newUser?.email ?? "";
            setNewRoles(currentRoles);
          }}
        />
      </DialogContent>
      <DialogActions>
        <MDTypography
          variant="caption"
          px={1}
          mr="auto"
          sx={{ fontWeight: "500", color: "#344767" }}
        >
          $10.99/Active Admin, Manager, or User
        </MDTypography>
        <MDButton
          variant="outlined"
          sx={dialogCancelButtonStyle}
          color="secondary"
          size="small"
          onClick={props.closeDialog}
        >
          CANCEL
        </MDButton>
        <TooltipWrapper
          placement="top"
          title={"User should have access to at least one portal."}
          disableHoverListener={totalAccessibleSites > 0}
        >
          <MDButton
            variant="gradient"
            color="info"
            size="medium"
            disabled={!hasValidUserData()}
            onClick={() => {
              const summary: UserFromAPI =
                props.userSummary ??
                getDefaultUserSummaryFromEmailAndPortals(
                  newUser.email,
                  newRoles.companyRoles.length
                );
              // Generating data grid object
              const userDataOnGrid = generateUserObjectForGrid(
                summary,
                newUser,
                {
                  ...newRoles,
                  email: newUser.email,
                }
              );
              props.onSave(userDataOnGrid);
            }}
          >
            SAVE
          </MDButton>
        </TooltipWrapper>
      </DialogActions>
    </>
  );
};

export default AddUserDialogContent;
