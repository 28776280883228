import {
  mdiFileExport,
  mdiRepeatVariant,
  mdiInformation,
  mdiCloseCircle,
} from "@mdi/js";
import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import { styled } from "@mui/material/styles";
import TooltipWrapper from "pages/components/TooltipWrapper";
import { ButtonWithIcon } from "@ivueit/vue-engine";

/// Styles applied to the "ButtonWithIcon" explicitly for the actionButtons in the topBar
export const StyledButtonWithIcon = styled(ButtonWithIcon)(({ theme }) => ({
  paddingLeft: "16px",
  paddingRight: "16px",
  "&:disabled": {
    color: "#C7CCD0",
    borderColor: "#C7CCD0",
  },
  svg: {
    width: "18px !important",
    height: "18px !important",
  },
}));

export enum ActionButtonType {
  export,
  reissue,
  makeUrgent,
  cancel,
}

interface Props {
  onButtonClick: (type: ActionButtonType) => void;
  shouldDisableReissueButton?: boolean;
  shouldDisableCancelButton?: boolean;
  shouldDisableMakeUrgentButton: boolean;
  containsReadOnlyVues: boolean;
}

/// Custom styles applied to Tooltip component
export const tooltipStyles = {
  bgcolor: "dark.main",
  borderRadius: "2px",
  fontSize: "12px",
  padding: "5px 8px",
  textAlign: "left",
  fontWeight: "300",
  lineHeight: "16px",
  "& .MuiTooltip-arrow": {
    color: "dark.main",
  },
};

export const VueActionBar = ({
  onButtonClick,
  shouldDisableReissueButton,
  shouldDisableCancelButton,
  shouldDisableMakeUrgentButton,
  containsReadOnlyVues,
}: Props) => {
  const readOnlyMessage = "One or more of the selected Vues are read only";

  const getReIssueButton = () => {
    const title = containsReadOnlyVues
      ? readOnlyMessage
      : "One or more of the selected Vues are not in the Completed status";
    const disableButton = containsReadOnlyVues || shouldDisableReissueButton;
    return (
      <TooltipWrapper title={title} disableHoverListener={!disableButton}>
        <StyledButtonWithIcon
          iconPath={mdiRepeatVariant}
          onClick={() => {
            onButtonClick(ActionButtonType.reissue);
          }}
          disabled={disableButton}
        >
          Reissue
        </StyledButtonWithIcon>
      </TooltipWrapper>
    );
  };

  const getCancelVuesButton = () => {
    const title = containsReadOnlyVues
      ? readOnlyMessage
      : "One or more of the selected Vues are not in the Open or In progress status";
    const disableButton = containsReadOnlyVues || shouldDisableCancelButton;
    return (
      <TooltipWrapper title={title} disableHoverListener={!disableButton}>
        <StyledButtonWithIcon
          iconPath={mdiCloseCircle}
          onClick={() => {
            onButtonClick(ActionButtonType.cancel);
          }}
          disabled={disableButton}
        >
          Cancel
        </StyledButtonWithIcon>
      </TooltipWrapper>
    );
  };

  const getMakeUrgentButtton = () => {
    const title = containsReadOnlyVues
      ? readOnlyMessage
      : "One or more of the selected Vues are not in the Open status or already marked as urgent.";
    const disableButton = containsReadOnlyVues || shouldDisableMakeUrgentButton;

    return (
      <TooltipWrapper title={title} disableHoverListener={!disableButton}>
        <StyledButtonWithIcon
          iconPath={mdiInformation}
          onClick={() => {
            onButtonClick(ActionButtonType.makeUrgent);
          }}
          disabled={disableButton}
        >
          Make Urgent
        </StyledButtonWithIcon>
      </TooltipWrapper>
    );
  };

  return (
    <MDBox display="flex" justifyContent="space-between">
      <Grid container spacing={1}>
        {/* Export Popup */}
        <Grid item>
          <StyledButtonWithIcon
            iconPath={mdiFileExport}
            onClick={() => {
              onButtonClick(ActionButtonType.export);
            }}
          >
            Export
          </StyledButtonWithIcon>
        </Grid>
        {/* Reissue Popup */}
        <Grid item>{getReIssueButton()}</Grid>
        {/* Make Urgent Popup */}
        <Grid
          item
          sx={{
            svg: {
              transform: "rotate(180deg)",
            },
          }}
        >
          {getMakeUrgentButtton()}
        </Grid>
        {/* Cancel Vues Popup */}
        <Grid item>{getCancelVuesButton()}</Grid>
      </Grid>
    </MDBox>
  );
};
