import Icon from "@mdi/react";
import MDBox from "components/MDBox";
import { mdiAlert, mdiCheckBold, mdiFileCheckOutline } from "@mdi/js";
import {
  notifyIcon,
  notifyIconPrimary,
  notifyIconDanger,
  notifyIconInfo,
} from "../NotificationStyles";
import { SxProps } from "@mui/material/styles";
import { NotificationType } from "../interfaces/interfaces";

export const CustomIcon = ({ type }: { type: NotificationType }) => {
  const getIconComponent = (sx: SxProps, iconPath: string, size: number) => {
    return (
      <MDBox sx={sx}>
        <Icon path={iconPath} size={size} />
      </MDBox>
    );
  };

  const generateIcon = () => {
    switch (type) {
      case NotificationType.vueCompleted:
        const completedStyle = { ...notifyIcon, ...notifyIconPrimary };
        return getIconComponent(completedStyle, mdiCheckBold, 1);
      case NotificationType.vueEscalated:
        const escalatedStyle = { ...notifyIcon, ...notifyIconDanger };
        return getIconComponent(escalatedStyle, mdiAlert, 0.72);
      case NotificationType.vueExported:
      case NotificationType.batchExported:
        const exportReadyStyle = { ...notifyIcon, ...notifyIconInfo };
        return getIconComponent(exportReadyStyle, mdiFileCheckOutline, 0.72);
      default:
        return <></>;
    }
  };

  return generateIcon();
};
